import { useContext } from "react";
import { useSelector } from "react-redux";
import { FeatureServicesContext } from "Root/context/isFeatureAppContext";
import { expandedShortcodeDetails } from "Root/store";

export enum OneHubTrackingEvents {
    VWBasic_AccordionClose_Click = "VWBasic_AccordionClose_Click",
    VWBasic_AccordionOpen_Click = "VWBasic_AccordionOpen_Click",
    VWBasic_Button_Click = "VWBasic_Button_Click",
    VWBasic_GenericFeatureApp_Load = "VWBasic_GenericFeatureApp_Load",
    VWBasic_SceneChange_Click = "VWBasic_SceneChange_Click",
}

export function useOHTracking() {
    let context = useContext(FeatureServicesContext);
    let uuid = useSelector((store) => store.app.uuid);
    let expandedShortcode = useSelector((store) => store.app.expandedShortcode as expandedShortcodeDetails);
    let fn = context?.services?.tracking?.track
        ? context.services.tracking.track.bind(context.services.tracking)
        : () => {};
    let result = (action: string, event: { [key: string]: object }) => {
        event = {
            ...event,
            eventInfo: { brand: "passenger" },
            content: { BrochureId: uuid },
            configuration: {
                Marketingcode: expandedShortcode?.shortcode,
                //BodyTypeName: 'STRING',
                CarlineId: expandedShortcode?.vgso?.expandedConfiguration?.carlineKey,
                CarlineName: expandedShortcode?.vgso?.consolidatedData?.carlineName,
                SalesgroupId: expandedShortcode?.vgso?.expandedConfiguration?.salesgroupKey,
                SalesgroupName: expandedShortcode?.vgso?.consolidatedData?.salesgroupName,
                EquipmentlineName: expandedShortcode?.consolidatedData?.meta?.modelFilter?.find(
                    (f) => f.type === "EquipmentLine"
                )?.text,
                ModelId: expandedShortcode?.vgso?.expandedConfiguration?.modelKey,
                //ModelTrimFeatures: 'STRING',
                ModelName: expandedShortcode?.vgso?.consolidatedData?.modelName,
                ModelYear: expandedShortcode?.vgso?.expandedConfiguration?.modelYear,
                ModelModelVersion: expandedShortcode?.vgso?.expandedConfiguration?.modelVersion,

                ExteriorColorCode: expandedShortcode?.vgso?.expandedConfiguration?.exterior?.code,
                ExteriorColorName: expandedShortcode?.vgso?.expandedConfiguration?.exterior?.name,
                InteriorColorCode: expandedShortcode?.vgso?.expandedConfiguration?.interior?.code,
                InteriorColorName: expandedShortcode?.vgso?.expandedConfiguration?.interior?.name,
                //WheelsBasicId: 'STRING',
                //WheelsBasicName: 'STRING',
                PriceCurrency: expandedShortcode?.consolidatedData?.meta?.configurationPrice?.total?.find(
                    (p) => p.type === "price"
                )?.currency,
                PriceBasis: expandedShortcode?.consolidatedData?.meta?.configurationPrice?.model?.find(
                    (p) => p.type === "price"
                )?.price,
                PriceExterior: expandedShortcode?.consolidatedData?.meta?.configurationPrice?.color?.find(
                    (p) => p.type === "price"
                )?.price,
                PriceOptions: expandedShortcode?.consolidatedData?.meta?.configurationPrice?.equipment?.find(
                    (p) => p.type === "price"
                )?.price,
                //PriceWheels: 'INTEGER',
                PriceTotalConfiguration: expandedShortcode?.consolidatedData?.meta?.configurationPrice?.total?.find(
                    (p) => p.type === "price"
                )?.price,
                OptionsNumber: expandedShortcode?.vgso?.vehicleConfiguration?.features?.length,
                OptionsList: expandedShortcode?.vgso?.vehicleConfiguration?.features?.map((f) => f.code),
                // vehicleBusinessModel: 'STRING',
                // ModelIsRecommendation: 'BOOLEAN',
                // ModelDriveType: 'STRING',
                ModelGearType: expandedShortcode?.consolidatedData?.meta?.modelFilter?.find((f) => f.type === "Gear")
                    ?.text,
                EngineName: expandedShortcode?.consolidatedData?.meta?.modelFilter?.find(
                    (f) => f.type === "Engine" || f.type === "Engine_2"
                )?.text,
                // EnginePowerHPKW: 'STRING',
                EngineFuelTypePrimary: expandedShortcode?.consolidatedData?.meta?.modelFilter?.find(
                    (f) => f.type === "FuelType"
                )?.text,
                // EngineFuelTypeNonPrimary: 'STRING',
                // EmissionCo2CombinedPrimary: 'NUMBER',
                // ConsumptionCombinedPrimary: 'INTEGER',
                // ConsumptionCombinedNonPrimary: 'INTEGER',
                // EmissionConsumptionEfficiencyClass: 'STRING',
                // EmissionConsumptionTestingMethod: 'STRING',
            },
        };
        console.log("Tracking event sent", action, event);

        return fn(action, event);
    };
    return result;
}

export function clickTracking(tracking: ReturnType<typeof useOHTracking>, name?: string) {
    return {
        onClick: (event: React.MouseEvent<any>) => {
            tracking(OneHubTrackingEvents.VWBasic_Button_Click, {
                link: {
                    name: (event.currentTarget as HTMLElement).textContent,
                    url: (event.currentTarget as any).href,
                },
            });
        },
    };
}
