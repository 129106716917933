import { getTitle } from "Root/utils/layout-tools";
import { getViewport } from "Root/utils/orientation";
import {
    APP_INIT,
    APP_SET_VIEWPORT,
    APP_SET_READY,
    APP_SET_ROUTES,
    APP_SET_LOCALE,
    APP_SET_VEHICLE,
    APP_SET_VEHICLE_COLOR,
    APP_SET_PAGE_TITLE,
    APP_SET_DEALERSHIP,
    APP_SET_WIDGETS,
    APP_SET_MAP,
    APP_SET_PERSONALISED_PARAMS,
    APP_SET_DYNAMIC_WLTP_DATA,
    APP_SET_DYNAMIC_SUMMARY_DATA,
    APP_SET_PRICE,
    APP_SET_TECHNICAL_SPECS,
    APP_SET_DISCLAIMER_FOOTER,
    APP_SET_VIDEO_PLAYPAUSE,
    APP_SET_PENDING,
    APP_SET_MODEL_NAME,
    APP_SET_EXPANDED_SHORTCODE,
} from "Store/actions";

export const defaultVehicle = {
    manufacturer: "vw",
    model: "id3_mp2",
    year: "2021",
    expandedPrCode: {},
};

const hardCodedDealership = {
    companyName: "Yes",
    addressType: "unknown",
    city: "Newcastle",
    postcode: "NU05 R45",
    email: "test@test.com",
    telephone: "07835 345736",
    street: "4 the street",
    street2: "none",
    lat: "54.96951580824808",
    lon: "-1.6043746539739652",
    domain: "www.google.com",
    domainRaw: "www.google.com",
};

const hardCodedDynamicSummary = {
    wheel: {
        mpsId: "D6RimTileMaxFit",
        referenceKey: "retrieveAssetsForConfiguration",
        type: "URL",
        asset: "https://r-media.volkswagen.com/v1/VW/10A/2022/MP2/20220411/de-DE-de/P8A1/TN/0NB,1CV,1J0,1KA,1LA,2FM,3A2,3D1,3FA,3GA,3L3,3NU,3QE,4GS,4I7,4KC,4L2,4S1,4UF,5JA,5XJ,6FG,6H0,6I1,6XN,7P0,7UT,7W0,7X2,7Y0,8AS,8G0,8IR,8RS,8VG,8ZQ,9P4,A8M,AV1,C8I,E0A,EL5,ER1,ES7,K8G,KA0,KS0,L0L,N6E,NZ4,QW5,UD0,VF4/D6RimTileMaxFit/b19d7b4a-df80-487c-908a-47785dbb35fc/9b05efd27b09bb8da27900fabbc0cfcdad7a22036f3560faa9a1ffac1f01671b.jpg",
        name: '4 Leichtmetallräder "Andoya" 7,5 J x 19 in Schwarz, Oberfläche in Penny Copper',
    },
    exterior: {
        mpsId: "D6ColorTile",
        referenceKey: "retrieveAssetsForConfiguration",
        type: "URL",
        asset: "https://r-media.volkswagen.com/v1/VW/10A/2022/MP2/20220411/de-DE-de/P8A1/TN/0NB,1CV,1J0,1KA,1LA,2FM,3A2,3D1,3FA,3GA,3L3,3NU,3QE,4GS,4I7,4KC,4L2,4S1,4UF,5JA,5XJ,6FG,6H0,6I1,6XN,7P0,7UT,7W0,7X2,7Y0,8AS,8G0,8IR,8RS,8VG,8ZQ,9P4,A8M,AV1,C8I,E0A,EL5,ER1,ES7,K8G,KA0,KS0,L0L,N6E,NZ4,QW5,UD0,VF4/D6ColorTile/b19d7b4a-df80-487c-908a-47785dbb35fc/35c1d47ed6d939fb5f83511d731101c3449c129e026911a2cd1d656f118db2b8.jpg",
        name: "Kings Red Metallic Schwarz",
    },
    interior: {
        mpsId: "D6InteriorTile",
        referenceKey: "retrieveAssetsForConfiguration",
        type: "URL",
        asset: "https://r-media.volkswagen.com/v1/VW/10A/2022/MP2/20220411/de-DE-de/P8A1/TN/0NB,1CV,1J0,1KA,1LA,2FM,3A2,3D1,3FA,3GA,3L3,3NU,3QE,4GS,4I7,4KC,4L2,4S1,4UF,5JA,5XJ,6FG,6H0,6I1,6XN,7P0,7UT,7W0,7X2,7Y0,8AS,8G0,8IR,8RS,8VG,8ZQ,9P4,A8M,AV1,C8I,E0A,EL5,ER1,ES7,K8G,KA0,KS0,L0L,N6E,NZ4,QW5,UD0,VF4/D6InteriorTile/b19d7b4a-df80-487c-908a-47785dbb35fc/2875604f758a4127979fd074b1e3d620545a231771d38c7e32c0906f73a5a312.jpg",
        name: "Dusty Grey Dark - Soul-Schwarz/ Soul-Schwarz - Dusty Grey Dark/ Schwarz/Schwarz",
        additionalInfo: [
            {
                element: "Sitze",
                color: "Dusty Grey Dark - Soul-Schwarz",
            },
            {
                element: "Instrumententafel",
                color: " Soul-Schwarz - Dusty Grey Dark",
            },
            {
                element: "Teppich",
                color: " Schwarz",
            },
            {
                element: "Lenkrad",
                color: "Schwarz",
            },
        ],
    },
};

const hardCodedSpecData = {
    gears: {
        localizedName: "lots",
        text: "gear stuff",
        id: "1",
    },
    maxPower: {
        localizedName: "power",
        text: "very powerful!",
        id: "2",
    },
    netBattery: {
        localizedName: "battery",
        text: "battery stuff",
        id: "3",
    },
    AcCharging: {
        code: { value: "something" },
        value: "something else",
    },
    battery: {
        code: { value: "something" },
        value: "something else",
    },
    emissionNorm: {
        code: { value: "something" },
        value: "something else",
    },
    maxTorque: {
        code: { value: "something" },
        value: "something else",
    },
};

const hardCodedDefaultState: Store.App = {
    ready: true,
    pending: false,
    uuid: undefined,
    locale: "en_gb",
    routes: undefined,
    widgets: undefined,
    viewport: getViewport(),
    vehicle: {
        ...defaultVehicle,
        expandedPrCode: {},
        shortcode: "123456",
    },
    title: getTitle(),
    dealership: hardCodedDealership,
    map: {
        apiKey: "AIzaSyDMUYfhhyxQ-d7P1vWdGqQbZWP5ET03su8",
        dealershipHasLocationCoords: true,
    },
    personalisedParams: {
        shortCode: "123456",
    },
    price: {
        totalPrice: 10000,
    },
    spec: hardCodedSpecData,
    dynamicSummary: hardCodedDynamicSummary,
    videoPlaying: false,
};

const defaultState: Store.App = {
    ready: false,
    pending: false,
    uuid: undefined,
    locale: "en_gb",
    routes: undefined,
    widgets: undefined,
    viewport: getViewport(),
    vehicle: defaultVehicle,
    title: getTitle(),
    dealership: undefined,
    map: undefined,
    personalisedParams: undefined,
    videoPlaying: true,
    modelName: undefined,
    expandedShortcode: undefined,
};

const runtimeDefaultState = process.env.STORYBOOK ? hardCodedDefaultState : defaultState;

export const AppReducer = (state = runtimeDefaultState, action: any) => {
    switch (action.type) {
        case APP_INIT:
            return {
                ...state,
                ready: false,
                ...action.payload,
            };
        case APP_SET_ROUTES:
            return {
                ...state,
                routes: action.payload,
            };
        case APP_SET_WIDGETS:
            return {
                ...state,
                widgets: action.payload,
            };
        case APP_SET_READY:
            return {
                ...state,
                pending: false,
                ready: true,
            };
        case APP_SET_PENDING:
            return {
                ...state,
                pending: true,
            };
        case APP_SET_VIEWPORT:
            return {
                ...state,
                viewport: action.payload,
            };
        case APP_SET_LOCALE:
            return {
                ...state,
                locale: action.payload,
            };
        case APP_SET_VEHICLE:
            return {
                ...state,
                vehicle: action.payload,
            };
        case APP_SET_VEHICLE_COLOR:
            return {
                ...state,
                colorPrCode: action.payload,
            };
        case APP_SET_PAGE_TITLE:
            return {
                ...state,
                title: action.payload,
            };
        case APP_SET_DEALERSHIP:
            return {
                ...state,
                dealership: action.payload,
            };
        case APP_SET_MAP:
            return {
                ...state,
                map: action.payload,
            };
        case APP_SET_PERSONALISED_PARAMS:
            return {
                ...state,
                personalisedParams: action.payload,
            };
        case APP_SET_DYNAMIC_WLTP_DATA:
            return {
                ...state,
                dynamicWLTP: action.payload,
            };
        case APP_SET_DYNAMIC_SUMMARY_DATA:
            return {
                ...state,
                dynamicSummary: action.payload,
            };
        case APP_SET_PRICE:
            return {
                ...state,
                price: action.payload,
            };
        case APP_SET_TECHNICAL_SPECS:
            return {
                ...state,
                spec: action.payload,
            };
        case APP_SET_MODEL_NAME:
            return {
                ...state,
                modelName: action.payload,
            };
        case APP_SET_EXPANDED_SHORTCODE:
            return {
                ...state,
                expandedShortcode: action.payload,
            };
        case APP_SET_DISCLAIMER_FOOTER:
            return {
                ...state,
                disclaimer: action.payload,
            };
        case APP_SET_VIDEO_PLAYPAUSE:
            return {
                ...state,
                videoPlaying: action.payload,
            };

        default:
            return state;
    }
};
