import { isDebugRuntime } from "Root/utils/debug";
import schemaEVTemplate from "Root/../public/schema/template-schema-ev.json";
import schemaEZRTemplate from "Root/../public/schema/template-schema-ezr.json";
import schemaCRMTemplate from "Root/../public/schema/template-schema-crm.json";
import schemaMasterTemplate from "Root/../public/schema/template-schema-master.json";
import { baseURL } from "Root/baseURL";

type schemaEV = typeof schemaEVTemplate;
type schemaEZR = typeof schemaEZRTemplate;
type schemaCRM = typeof schemaCRMTemplate;
type schemaMaster = typeof schemaMasterTemplate;
export type schemaTyped = schemaEV & schemaEZR & schemaCRM & schemaMaster;
export const MAX_SCHEMA_GET_RETRIES = 180;

/**
 * Returns the fallback/dev version of the schema
 */
export const getFallbackSchema = async (schemaType: string): Promise<any> => {
    console.warn(`Fallback flag in URL - Presenting dummy schema for ${schemaType}`);
    const fetchResponse = await fetch(`${baseURL}/schema/template-schema-${schemaType}.json`);

    try {
        return updateUrls(await fetchResponse.json());
    } catch (e) {
        console.warn(`[getFallbackSchema]: Failed with ${e}`);

        return null;
    }
};

/**
 * Tries to retrieve the STATUS:OK schema
 */
export const attemptGetSchema = async (uuid: string, singleRun?: boolean) => {
    let schemaURI = process.env.REACT_APP_SCHEMA_LOCATION?.replace("ENV", "spotlight.mediastore.live");
    const localdevSchemaURI = process.env.REACT_APP_LOCAL_SCHEMA_LOCATION;

    const params = new URLSearchParams(window.location.search);
    const bucket = params.get("bucket");
    const slenv = params.get("env");

    if (isDebugRuntime) {
        if (bucket) {
            schemaURI = localdevSchemaURI?.replace("BUCKET_NAME", bucket);

            console.warn(`[attemptGetSchema] => Using devlocal s3 ${localdevSchemaURI}`);
        }

        if (slenv) {
            console.warn(`[attemptGetSchema] => Using ${slenv} s3 bucket for schema lookup`);
            schemaURI = process.env.REACT_APP_SCHEMA_LOCATION?.replace("ENV", `zlcc.mediastore.${slenv}`);
        }
    }

    if (!schemaURI) {
        console.error(`[attemptGetSchema] => No Schema URI located - check .env for REACT_APP_SCHEMA_LOCATION`);
    }

    const fetchResponse = await fetch(`${schemaURI}/${uuid}.json`);

    if (fetchResponse.status === 200) {
        return updateUrls(await fetchResponse.json());
    } else {
        if (singleRun) {
            return null;
        }

        console.debug(`[attemptGetSchema] => Status is not OK - retrying`);
        throw new Error(`[attemptGetSchema] => Reached max retries (${MAX_SCHEMA_GET_RETRIES})`);
    }
};

function updateUrls(input: object) {
    function walk(o: any, updateString: (input: string) => string) {
        Object.entries(o).forEach(([key, value]) => {
            if (typeof value === "string") o[key] = updateString(value);
            else if (value && typeof value === "object") walk(value, updateString);
        });
    }
    walk(input, (s) => s.replace(/^assets\//, baseURL + "/assets/"));
    return input;
}
