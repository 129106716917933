import React, { useEffect } from "react";
import { useMedia } from "react-use";
import { useStoreSetAppViewport } from "Root/hooks";
import {
    IS_DESKTOP_MATCH_MEDIA_QUERY,
    IS_DESKTOP_MEDIUM_MATCH_MEDIA_QUERY,
    IS_TABLET_MATCH_MEDIA_QUERY,
} from "Root/utils/orientation";

const useIsDesktopMedium = (): boolean => {
    return useMedia(IS_DESKTOP_MEDIUM_MATCH_MEDIA_QUERY);
};

const useIsDesktop = (): boolean => {
    return useMedia(IS_DESKTOP_MATCH_MEDIA_QUERY);
};

const useIsTablet = (): boolean => {
    return useMedia(IS_TABLET_MATCH_MEDIA_QUERY);
};

export const UtilAppOrientation: React.FC = () => {
    const isDesktopMedium = useIsDesktopMedium();
    const isDesktop = useIsDesktop();
    const isTablet = useIsTablet();
    const setAppViewport = useStoreSetAppViewport();

    useEffect(() => {
        if (isDesktopMedium) {
            setAppViewport("desktopMedium");
        } else if (isDesktop) {
            setAppViewport("desktop");
        } else if (isTablet) {
            setAppViewport("tablet");
        } else {
            setAppViewport("mobile");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktopMedium, isDesktop, isTablet]);

    return null;
};
