/** Available runtime debug levels */
type RuntimeDebugLevel = "info" | "verbose";

/**
 * Determine if debug mode should be used at runtime
 */
export const getRuntimeDebugLevel = (): RuntimeDebugLevel | undefined => {
    // Extract debug param from URL
    const debugParam = new URLSearchParams(window.location.search).get("debug");

    if (debugParam !== null && debugParam !== "false") {
        return debugParam === "verbose" ? debugParam : "info";
    }

    // If build has debug set
    if (process.env.NODE_ENV === "development") {
        return "info";
    }

    return undefined;
};

/** Current debug level @see `RuntimeDebugLevel` type */
export const runtimeDebugLevel = getRuntimeDebugLevel();
export const isDebugRuntime = typeof runtimeDebugLevel === "string";
