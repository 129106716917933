import React from "react";
import styled from "@emotion/styled";
import LoadableVisibility from "react-loadable-visibility/loadable-components";

/** Individual app route definition */
export interface AppRoutesPropsNode extends Pick<Store.StoreRouteRoutesNode, "pageId" | "path" | "selectorId"> {
    component: React.FC<any>;
    mobileOnly?: boolean;
    position?: string;
    background?: string;
    behaviour?: string[];
}

/** Individual widget definition */
interface AppWidgetsNode {
    selectorId: string;
    widgetId: string;
    component: React.FC;
}

/** Collection of app routes */
export type AppRoutesProps = AppRoutesPropsNode[];

/** Collection of app widgets */
export type AppWidgetsProps = AppWidgetsNode[];

/** @important - keep min height at 100vh, otherwise will load more then needed */
const LoadingComponentRoot = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

/**
 * Dynamically fetch lazy loaded routes based on `component` prop in JSON page item
 */
export const fetchAppRoutesLazy = async (routes: Store.StoreRouteRoutes): Promise<AppRoutesProps> => {
    const appRoutes: AppRoutesProps = [];

    /** For those components we have since deprecated, for older microsite supports */

    for (const { component, ...value } of Object.values(routes)) {
        /** Loads the component once its in view, otherwise returns a placeholder */
        const LoadableComponent = await LoadableVisibility(
            () =>
                import(
                    /* webpackPrefetch: true */ `../components/${
                        component === "LessCarTurntable" ? "EZRTurntable" : component
                    }.tsx`
                ),
            {
                fallback: <LoadingComponentRoot />,
            }
        );

        /* eslint-enable prefer-template */
        appRoutes.push({
            component: LoadableComponent,
            ...value,
        });
    }

    return appRoutes;
};

/**
 * Dynamically fetch the widgets required for the microsite
 */
export const fetchWidgets = async (widgets: Store.StoreWidgets): Promise<AppWidgetsProps> => {
    const appWidgets: AppWidgetsProps = [];

    for (const value of Object.values(widgets)) {
        /** Loads the component once its in view, otherwise returns a placeholder */
        const LoadableComponent = await LoadableVisibility(
            () => import(/* webpackPrefetch: true */ `../components/widgets/${value.component}.tsx`),
            {
                fallback: <LoadingComponentRoot />,
            }
        );

        /* eslint-enable prefer-template */
        appWidgets.push({
            component: LoadableComponent,
            selectorId: value.id,
            widgetId: value.widgetId,
        });
    }

    return appWidgets;
};
