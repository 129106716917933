import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { Article } from "./Article";
import { Button } from "./Button";
import { useOHTracking, clickTracking } from "Root/utils/tracking";

interface CodeExpiredProps extends BoxProps {
    heading?: any;
    info?: string | string[];
    cta?: string;
    to?: string;
    fontColor?: string;
}

const flexColumn = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
};
const wrapperSx = {
    h: "100vh",
    w: { base: "100vw" },
    m: "auto",
    ...flexColumn,
};

const articleSx = {
    mb: 6,
    ...flexColumn,
};

export const CodeExpired: React.FC<CodeExpiredProps> = ({ ...props }) => {
    const tracking = useOHTracking();
    return (
        <Box sx={wrapperSx}>
            <Article color={props.fontColor} heading={props.heading} copy={props.info} sx={articleSx} />
            {props.cta && (
                <Button as="a" variant="primary" size="large" href={props.to} {...clickTracking(tracking)}>
                    {props.cta}
                </Button>
            )}
        </Box>
    );
};
