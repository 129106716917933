import { baseURL } from "Root/baseURL";
import { isDebugRuntime } from "Root/utils/debug";

export const getMessages = async (
    locale: string,
    brochureType: string = "ev",
    carModel: string,
    isCommon?: boolean,
    isFallback?: boolean
): Promise<any> => {
    let response;
    let fetchPath: string;

    if (locale === "tags" && isDebugRuntime) {
        console.info(`[Messages] - returning tags for messages for debug/context purposes`);
        return {
            data: [],
            locale: locale,
        };
    }

    /**
     * @TODO - Remove the check for EV here
     * the ev-check-app messages files were wrote first
     * and don't have the brochure type added so we ignore
     * it for the time being.
     */
    fetchPath = `${brochureType}/${locale}.json`;

    if (carModel) {
        const carModelSplit = carModel.split("_");

        fetchPath = `${brochureType}/${carModelSplit[0]}/${locale}.json`;
    }

    if (isCommon) {
        fetchPath = `${brochureType}/common_${locale}.json`;
    }

    response = await fetch(`${baseURL}/locales/${fetchPath}`);

    async function fallback() {
        console.warn(`[Messages] - Could not find messages for locale ${fetchPath}`);

        /**
         * If we have caught inside of english fallback, then fallback to german
         */
        if (isFallback && locale === "en_gb") {
            console.warn(`[Messages] - ${fetchPath} not found, presenting german fallback`);
            return await getMessages("de_de", brochureType, carModel, false, true);
        }

        if (isFallback) {
            return {};
        }

        /**
         * Call the function again but this time get the default english in
         */
        return await getMessages("en_gb", brochureType, carModel, isCommon, true);
    }
    /**
     * For some reason when deving agaisnt local
     * the status will be 200 regardless of if
     * the above locale file actually exists or not.
     */
    if (response.status === 200) {
        try {
            /**
             * Try to parse the json file
             * If it doesnt work then its probably not a json file
             */
            const messages = await response.json();

            console.info(`[Messages] - returning messages for ${fetchPath}`);

            return {
                data: messages,
                locale: locale,
            };
        } catch (e) {
            console.warn(`[Messages] - Could not find messages for locale ${fetchPath}`);

            return await fallback();
        }
    }
    console.warn(`[Messages] - returned a none-200 status for ${fetchPath}`);
    return await fallback();
};

const wrappedTags = ["sub", "sup", "bold"];

export const parseTemplateStrings = (
    messageIds: string[],
    messages: Record<string, any>,
    schema: Record<string, any>
) => {
    let stringJson = JSON.stringify(schema);
    let tags: Record<string, string> = {};
    wrappedTags.forEach((t) => {
        tags[t] = `<${t}>`;
        tags["/" + t] = `</${t}>`;
    });
    messageIds = [...messageIds, "new-line", ...wrappedTags, ...wrappedTags.map((t) => "/" + t)];
    messages = { ...messages, ...tags, "new-line": "<br/>" };
    messageIds.forEach((messageId) => {
        stringJson = stringJson.replaceAll(`\${${messageId}}`, messages[messageId]);
    });

    return JSON.parse(stringJson);
};
