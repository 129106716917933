import { put, select } from "redux-saga/effects";
import { appSetLocale, appSetTitle, templateSet, messagesSet } from "Root/store/actions";
import { getMessages, parseTemplateStrings } from "./locales";

const messagesFromStore = (store: Store.Store) => store.messages;

export function* localiseSchemaPlaceholdersSaga(schema: any, carModel: any) {
    const params = new URLSearchParams(window.location.search);
    const modelOverrideParam = params.get("model");
    const queryStringLocale = params.get("locale");

    let schemaType = schema.data.document.type;
    let locale =
        queryStringLocale ||
        schema.data.document.locale?.content ||
        schema.data.document.locale ||
        (schemaType === "crm" ? "de_de" : "en_gb");

    /**
     * Get the messages for the locale and set the app locale
     *
     * Merges the following translation files
     * common: used by all cars/models in a given microsite and locale combination
     * specific: used by a specific car model for a locale
     */
    const { data: micrositeCommonMessages } = yield getMessages(
        locale.toLowerCase(),
        schema.data.document.type,
        modelOverrideParam ? modelOverrideParam : carModel,
        true
    );

    const { data: specificMicrositeModelMessages, locale: messagesLocale } = yield getMessages(
        locale.toLowerCase(),
        schema.data.document.type,
        modelOverrideParam ? modelOverrideParam : carModel
    );

    const mergedCommonAndSpecificLocale = {
        ...micrositeCommonMessages,
        ...specificMicrositeModelMessages,
    };

    const messageIds = Object.keys(mergedCommonAndSpecificLocale);

    /** Reset the original locale, now with the locale selected from the messages files */
    yield put(appSetLocale(messagesLocale || locale));

    /**
     * Swap template placeholders in the schema with the correct locale copy
     */
    const translatedSchema = parseTemplateStrings(messageIds, mergedCommonAndSpecificLocale, schema);
    yield put(templateSet(translatedSchema));
    yield put(messagesSet(mergedCommonAndSpecificLocale));

    /**
     * Set the page title
     */
    const pageTitleFromSchema = translatedSchema.data.document.meta.title.content;
    yield put(appSetTitle(pageTitleFromSchema));
}

export function* replaceDynamicSchemaPlaceholdersSaga(schema: any, dynamicItems: Record<string, any>): any {
    /**
     * Swap template placeholders in the schema with the correct locale copy
     */
    const messages = yield select(messagesFromStore);

    const translatedSchema = parseTemplateStrings(Object.keys(dynamicItems), dynamicItems, schema);
    const translatedMessages = parseTemplateStrings(Object.keys(dynamicItems), dynamicItems, messages);
    yield put(templateSet(translatedSchema));
    yield put(messagesSet(translatedMessages));

    /**
     * Set the page title
     */
    const pageTitleFromSchema = translatedSchema.data.document.meta.title.content;
    yield put(appSetTitle(pageTitleFromSchema));
}
