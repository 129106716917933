import { VCSO, VGF, VGS, VGSO } from "@zerolight-vw/vw-api";
import { environmentTenant } from "Root/featureapp";
import { OneAPIKey } from "~/OneAPIConfig";

interface BasicDealershipInfo {
    addressen: BasicDealershipAddress[];
}

interface BasicDealershipAddress {
    firmierung: string;
    adressart: string;
    ort: string;
    plz: string;
    email: string;
    telefon: string;
    strasse: string;
    strasse2: string;
    latitude: string;
    longitude: string;
}

interface BasicDealershipResponse {
    Standort: BasicDealershipInfo[];
}

export const getDealershipDetails = async (uri: string, params: any): Promise<BasicDealershipResponse | null> => {
    const queryString = Object.keys(params)
        .map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        })
        .join("&");

    const dealershipLookupConstructedURL = `${uri}?${queryString}`;

    try {
        const response = await fetch(dealershipLookupConstructedURL);

        if (response.status === 200) {
            return await response.json();
        }

        return null;
    } catch (e) {
        return null;
    }
};

/*
serviceId: 'Prelive',
					validCodeRegex: '^[a-zA-Z0-9]{6,8}(,.+)?$',
					endpoint: 'https://content.oneapi.volkswagen.com/vgf',
					headers: {
						'x-api-key': 'XcKi0otnvE16ycyqNZFTSj2LH8e2udme',
						authorization: 'Basic YXBpLWNvbnN1bWVyLWNvbnQyOmZuVTU0ZG1nNktiemoyNEFzdw==',
					},
*/

const liveServices = {
    vgf: new VGF({
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
            },
        },
    }),
    vgso: new VGSO({
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
            },
        },
    }),
    vcso: new VCSO({
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
            },
        },
    }),
    vgs: new VGS({
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
            },
        },
    }),
};

const preliveServices = {
    vgf: new VGF({
        baseUrl: "https://content.oneapi.volkswagen.com/vgf",
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
                authorization: "Basic YXBpLWNvbnN1bWVyLWNvbnQyOmZuVTU0ZG1nNktiemoyNEFzdw==",
            },
        },
    }),
    vgso: new VGSO({
        baseUrl: "https://content.oneapi.volkswagen.com/vgso",
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
                authorization: "Basic YXBpLWNvbnN1bWVyLWNvbnQyOmZuVTU0ZG1nNktiemoyNEFzdw==",
            },
        },
    }),
    vcso: new VCSO({
        baseUrl: "https://content.oneapi.volkswagen.com/vcso",
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
                authorization: "Basic YXBpLWNvbnN1bWVyLWNvbnQyOmZuVTU0ZG1nNktiemoyNEFzdw==",
            },
        },
    }),
    vgs: new VGS({
        baseUrl: "https://content.oneapi.volkswagen.com/vgs",
        baseApiParams: {
            headers: {
                "x-api-key": OneAPIKey,
                authorization: "Basic YXBpLWNvbnN1bWVyLWNvbnQyOmZuVTU0ZG1nNktiemoyNEFzdw==",
            },
        },
    }),
};

export const expandShortcodeDetailsInternal = async (shortcode: string, template, services = liveServices) => {
    try {
        const pricingParams = JSON.parse(template.data.document.additionalTemplateData.PricingParams?.content ?? "{}");

        const { vgf, vgs, vgso, vcso } = services;
        const vgsoResponse = await vgso.loadStorageEntry
            .loadStorageEntryUsingGet({ marketingCode: shortcode })
            .catch(() => ({ data: undefined }));
        const vgsResponse = await vgs.loadStorageDataset
            .loadStorageDatasetUsingGet({ marketingcode: shortcode })
            .catch(() => ({ data: undefined }));

        const response = await vgf.garage.configurationLoad(shortcode);
        const tenant = response.data.tenantRefinement?.tenantKey! ?? environmentTenant;
        const configStepData = await vcso.configStep
            .configStepUsingPost(
                {
                    tenant,
                    fetchPrices: true,
                    fetchMediaForFeatures: true,
                    fetchExpansion: true,
                    fetchMediaForConfiguration: true,
                    fetchWltp: true,
                },
                {
                    vehicleConfiguration: vgsResponse.data?.vehicleConfiguration as any,
                }
            )
            .then((r) => r.data)
            .catch(() => undefined);
        const priceRequest = { ...pricingParams };
        delete priceRequest.price;
        const pricingData =
            Object.keys(priceRequest).length !== 0
                ? await vcso.configPrices
                      .configPricesUsingGet(configStepData?.vehicleConfiguration?.pasDataIdentifier!, {
                          tenant,
                          ...pricingParams,
                      })
                      .then((r) => r.data)
                      .catch(() => undefined)
                : undefined;

        if (response.data) {
            try {
                const responseJson = response.data;

                if (!responseJson.consolidatedData) return null;

                return {
                    ...responseJson,
                    vgso: vgsoResponse?.data,
                    vgs: vgsResponse.data,
                    shortcode,
                    configStepData,
                    pricingData,
                    priceOverride: pricingParams.price,
                };
            } catch (e) {
                return null;
            }
        }

        return null;
    } catch (e) {
        return null;
    }
};

export const expandShortcodeDetails = async (shortcode: string, template) => {
    return (
        (await expandShortcodeDetailsInternal(shortcode, template)) ||
        (await expandShortcodeDetailsInternal(shortcode, template, preliveServices))
    );
};

export const expandFooterDisclaimerDetails = async (locale?: string, keys?: string[]) => {
    if (!locale || !keys) return null;

    const localeString = locale.split("_");
    const localeCode = localeString[1];
    const language = localeString[0];

    try {
        const response = await fetch(
            `https://cdn6.prodworksngwapi.de/i18n/resources/translations/?tenant=vw-${localeCode}&language=${language}&inherit=true&application=D6CONFIGURATOR&inherit=true`
        );

        if (response.status === 200) {
            const jsonResponse = await response.json();
            const disclaimerModel = jsonResponse.translationBundles[language].translation.ihdcc.disclaimers.content;
            const disclaimerPairs = Object.entries(disclaimerModel);

            const correctDisclaimers = disclaimerPairs.filter((disclaimer: any) => {
                if (keys.includes(disclaimer[0])) {
                    if (disclaimer[1] !== null) {
                        return disclaimer[1];
                    }
                }

                return null;
            });

            const values = correctDisclaimers.map((disclaimerValue) => disclaimerValue[1]);

            return values;
        }

        return null;
    } catch (e) {
        return null;
    }
};
