import { applyMiddleware, createStore, Middleware, Store } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import { isDebugRuntime } from "./../utils/debug";
import { RootReducer } from "./reducers/root";
import { rootSaga } from "./sagas/root";

export type RootState = Store.Store;

export const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares: Middleware[] = [sagaMiddleware];

    if (isDebugRuntime) {
        middlewares.push(reduxImmutableStateInvariant());
    }

    const middlewaresEnhancer = applyMiddleware(...middlewares);
    const composedEnchancers = isDebugRuntime ? composeWithDevTools(...[middlewaresEnhancer]) : middlewaresEnhancer;

    const store = createStore(RootReducer, composedEnchancers);

    sagaMiddleware.run(rootSaga);

    return {
        store: store,
    };
};
