import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ErrorBoundary } from "Components/ErrorBoundary";
import { Loading } from "Components/shared/Loading";
import ShortcodeExpired from "Components/widgets/ShortcodeExpired";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useIntersection } from "react-use";
import { useStoreAppIsDesktop, useStoreAppSetVideoPlaying, useStoreTemplateID } from "Root/hooks";
import { AppRoutesProps, AppRoutesPropsNode, fetchAppRoutesLazy } from "Utils/app-routes";
import { isDebugRuntime } from "./../../utils/debug";

interface AppRoutesRouteRootProps {
    position?: string;
    background?: string;
}

const AppRoutesRouteRoot = styled.div<AppRoutesRouteRootProps>`
    width: 100%;
    position: relative;
    background: white;
    ${(props) =>
        props.background &&
        css`
            background: ${props.background};
        `};
    ${(props) =>
        props.position &&
        css`
            position: ${props.position};
            top: 0;
        `};
`;

const AppRoutesRouteRenderer: React.FC<AppRoutesPropsNode> = ({
    selectorId,
    component: Component,
    pageId,
    ...props
}) => {
    (Component as any).preload();
    const setVideoPlaying = useStoreAppSetVideoPlaying();
    const appRouteWrapperRef = useRef<HTMLDivElement>(null);
    const intersectionTop = useIntersection(appRouteWrapperRef, {
        rootMargin: "0px 0px -100%",
        threshold: 0,
    });
    const intersectionBottom = useIntersection(appRouteWrapperRef, {});

    useEffect(() => {
        if (intersectionTop && props.behaviour && props.behaviour.includes("stopVideo")) {
            if (intersectionTop.isIntersecting) {
                setVideoPlaying(false);
            }
        }

        if (intersectionBottom && props.behaviour && props.behaviour.includes("stopVideo")) {
            if (intersectionBottom.isIntersecting) {
                setVideoPlaying(true);
            }
        }
    }, [intersectionTop, appRouteWrapperRef, props.behaviour, setVideoPlaying, intersectionBottom]);

    return (
        <AppRoutesRouteRoot id={selectorId} ref={appRouteWrapperRef} {...props}>
            <Component {...props} pageid={pageId} />
        </AppRoutesRouteRoot>
    );
};

interface RoutesWrapperProps {
    ready: boolean;
}

interface RouteRendererProps {
    appRoutes: AppRoutesProps;
}

const RoutesWrapper = styled.main<RoutesWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    ${(props) =>
        !props.ready &&
        `
    	height: 100vh;
		overflow: hidden;
	`}
`;

export const UtilAppRoute: React.FC<RouteRendererProps> = ({ appRoutes }) => {
    const isDesktop = useStoreAppIsDesktop();
    const microsite = useStoreTemplateID();
    const shortcode = useSelector((state) => state.app.personalisedParams?.shortCode);
    const shortcodeExists = microsite === "master" || microsite === "crm" ? shortcode : true;

    return (
        <Route path="/">
            <Loading />

            {!shortcodeExists && !isDebugRuntime && (
                <ErrorBoundary>
                    <ShortcodeExpired />
                </ErrorBoundary>
            )}

            {appRoutes &&
                (shortcodeExists || isDebugRuntime) &&
                appRoutes.map((appRoute) => {
                    if (appRoute.mobileOnly && isDesktop) {
                        return null;
                    }

                    return (
                        <ErrorBoundary key={appRoute.path}>
                            <AppRoutesRouteRenderer {...appRoute} />
                        </ErrorBoundary>
                    );
                })}
        </Route>
    );
};

export const UtilAppRoutes: React.FC = () => {
    const storeRoutes = useSelector((store) => store.app.routes);
    const appReady = useSelector((store) => store.app.ready);

    const [appRoutes, setAppRoutes] = useState<AppRoutesProps | null>(null);
    const [routesDidFail, setRoutesDidFail] = useState(false);

    useEffect(() => {
        if (!storeRoutes) {
            return;
        }

        fetchAppRoutesLazy(storeRoutes)
            .then((routes) => {
                setAppRoutes(routes);
            })
            .catch((error) => {
                setRoutesDidFail(true);
            });
    }, [storeRoutes]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!appRoutes) {
        return <Loading />;
    }

    if (routesDidFail) {
        return <div>Critical Error</div>;
    }

    return (
        <RoutesWrapper id="routesWrapper" ready={appReady}>
            <Switch>
                <UtilAppRoute appRoutes={appRoutes} />
            </Switch>
        </RoutesWrapper>
    );
};
