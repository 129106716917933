import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";

interface SpinnerProps {
    variant?: "default" | "large" | "x-large"; // similar to icon sizes, 24px, 48px, 64px
    title?: string;
}

const radius = 7; // in a 24x24 svg viewbox
const circumference = 2 * Math.PI * radius;
const spin = keyframes`
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(2turn);
    }
`;
const growth = keyframes`
    0% {
        stroke-dashoffset: ${circumference};
    }
    50% {
        stroke-dashoffset: ${circumference / 4};
    }
    100% {
        stroke-dashoffset: ${circumference};
    }
`;

const StyledSpinner = styled.svg`
    width: 3rem;
    height: 3rem;
    stroke-width: 1px;
`;

const BackgroundPath = styled.path`
    stroke: disabled;
`;

const ForegroundPath = styled.path`
    stroke: white;
    stroke-dasharray: ${circumference} ${circumference};
    transform-origin: center;

    animation: 2s cubic-bezier(0.41, 0.2, 0.16, 0.59) ${spin} infinite,
        2s cubic-bezier(0.63, 0, 0.58, 1) ${growth} infinite;
`;

const circlePathData = "M12,5a7,7 0 1,0 0,14a7,7 0 1,0 0,-14";

export const Spinner: React.FunctionComponent<SpinnerProps> = (props) => (
    <StyledSpinner
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        viewBox="0 0 24 24"
    >
        {props.title && <title>{props.title}</title>}
        <BackgroundPath d={circlePathData} />
        <ForegroundPath d={circlePathData} />
    </StyledSpinner>
);
