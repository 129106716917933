import React from "react";

export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error(`[FailureInComponent] - ${error} - ${errorInfo}`);
    }

    render() {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}
