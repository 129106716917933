import { combineReducers } from "redux";

import { AppReducer } from "Store/reducers/app";
import { TemplateReducer } from "Store/reducers/template";
import { MessagesReducer } from "./messages";

//used to be the root reducer
export const delegatedReducer = combineReducers({
    app: AppReducer,
    template: TemplateReducer,
    messages: MessagesReducer,
});

//a new root reducer that delegates all actions
//except if its `STORE_CLEAR` being called, then it sets
//the state to undefined and returns the initial state of the store
export const RootReducer = (state: any, action: any) => {
    if (action.type === "STORE_CLEAR") {
        state = undefined;
    }

    return delegatedReducer(state, action);
};
