import { ComponentStyleConfig } from "@chakra-ui/react";

export const themeDef = {
    baseStyle: {
        fontFamily: "bodyBase",
        fontWeight: "normal",
        textStyle: "heading",
    },
    variants: {
        subheading: {
            textStyle: "subheading",
        },
    },
    defaultProps: { size: "none" },
};

export type ThemeDef = Omit<ComponentStyleConfig, "variants"> & typeof themeDef;
