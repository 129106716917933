import { SagaIterator } from "redux-saga";
import { call, delay, put, retry, select, takeLatest } from "redux-saga/effects";

import {
    appSetPending,
    appSetReady,
    appSetRoutes,
    appSetVehicle,
    appSetWidgets,
    appTriggerExternal,
    APP_EXTERNAL_TRIGGER_FINISH,
    APP_INIT,
    templateSet,
} from "Store/actions";

import { getTemplateRoutes, getTemplateWidgets } from "Root/utils/template-parsing";
import { isDebugRuntime } from "Root/utils/debug";
import {
    attemptGetSchema,
    getFallbackSchema,
    MAX_SCHEMA_GET_RETRIES,
    schemaTyped,
} from "Root/store/sagas/side-effects/schema";

import { id3Colors } from "Store/id3-pr-codes";
import { localiseSchemaPlaceholdersSaga, replaceDynamicSchemaPlaceholdersSaga } from "./side-effects/messages";
import { defaultVehicle } from "../reducers/app";

const SECOND = 1000;
const storeSchema = (store: Store.Store) => store.template;
const storeVehicle = (store: Store.Store) => store.app.vehicle;
const storeWLTP = (store: Store.Store) => store.app.dynamicWLTP;
const storeModelName = (store: Store.Store) => store.app.modelName;

function* appInit(): any {
    const params = new URLSearchParams(window.location.search);
    const uuid = params.get("u");
    const forceDefault = params.get("default");

    let schema: schemaTyped;

    if (!uuid) {
        console.warn("No UUID was supplied");

        if (isDebugRuntime && forceDefault) {
            const forceDefaultIdDecoded = decodeURI(forceDefault);

            schema = yield getFallbackSchema(forceDefaultIdDecoded);
        } else {
            return;
        }
    } else {
        schema = yield call(attemptGetSchema, uuid, true);

        if (schema === null) {
            yield put(appSetPending());
            schema = yield retry(MAX_SCHEMA_GET_RETRIES, 5 * SECOND, attemptGetSchema, uuid);
        }
    }

    if (schema) {
        let schemaType = schema.data.document.type;

        /**
         * The shortcode has two homes. Older Microsites (Pre Oct 2021)
         * were passing it in as a param into the actual schema. Later we
         * made this a value that spotlight writes.
         */
        const schemaHasShortcode =
            schema.data.service.stream.config?.setupShortcode?.code || schema.data.document.shortcode;
        let colorCode;

        if (!schemaType) {
            console.warn("No schema type could be gleamed from the schema, expect issues in content");
            schemaType = "ev";
        }

        yield put(templateSet(schema));

        /**
         * @TODO Type these using an input schema
         */
        const setupPrCodes = (schema as any).data?.service?.stream?.config?.setupPrCodes;
        const car = (schema as any).data?.service?.stream?.config?.car || defaultVehicle;

        /**
         * Only used in VW: EV CheckApp
         */
        if (setupPrCodes) {
            id3Colors.forEach((applicableID3Color) => {
                if (setupPrCodes.includes(applicableID3Color)) {
                    colorCode = applicableID3Color;
                }
            });
        }

        const vehicleFromSchema = {
            ...car,
            expandedPrCode: {
                exterior: colorCode,
            },
            prcode: setupPrCodes,
            shortcode: schemaHasShortcode,
        };

        /**
         * Car information only exists on "for real" schemas from spotlight
         */
        yield put(appSetVehicle(vehicleFromSchema));

        /**
         * Fetch the routes for the app
         */
        const routesFromSchema = getTemplateRoutes(schema.data.document.template);
        yield put(appSetRoutes(routesFromSchema));

        if (schema.data.document.widgets) {
            const widgetsFromSchema = getTemplateWidgets(schema.data.document.widgets);
            yield put(appSetWidgets(widgetsFromSchema));
        }

        // And finally add localised and dynamic swap strings to the schema
        yield localiseSchemaPlaceholdersSaga(schema, car.model);

        yield put(appTriggerExternal(schema));
    }
}

function* appSetReadyFinal(): any {
    const schema = yield select(storeSchema);
    const car = yield select(storeVehicle);
    const wltp = yield select(storeWLTP);
    const modelName = yield select(storeModelName);
    let carModel = car.model;

    /**
     * Transform "id3" to "ID.3"
     */
    if (carModel === "id3" || carModel === "id4") {
        carModel = carModel.toUpperCase();
        carModel = [carModel.slice(0, 2), ".", carModel.slice(2)].join("");
    }

    /**
     * A list of keys, mapped to values
     * that can be used throughout the locale files
     *
     * @example "Say hello to your new ${_carModel_}"
     */
    const dynamicItems = {
        _carModel_: carModel,
        _carModelTrim_: `<span style='white-space:nowrap'>${
            modelName || car?.expandedPrCode?.trim?.label || car.model
        }</span>`,
        _wltpCO2_: wltp?.CO2,
        _wltpConsumptionAmount_: wltp?.consumptionAmount,
        _wltpConsumptionUnit_: wltp?.consumptionUnit,
        _wltpEffeciencyClass_: wltp?.effeciencyClass,
    };

    // And finally add localised and dynamic swap strings to the schema
    yield replaceDynamicSchemaPlaceholdersSaga(schema, dynamicItems);
    yield delay(500);
    yield put(appSetReady());
}

// Watchers
export function* appSaga(): SagaIterator {
    yield takeLatest(APP_INIT, appInit);
    yield takeLatest(APP_EXTERNAL_TRIGGER_FINISH, appSetReadyFinal);
}
