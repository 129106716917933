import React from "react";
import { Box, BoxProps, Text, TextProps } from "@chakra-ui/react";
import { Heading } from "./Heading";
import { Frame } from "./Frame";

interface ArticleProps extends BoxProps {
    heading?: any;
    copy?: string | string[];
}

interface CopyProps extends TextProps {
    text?: string;
}

const textStyleMeta = {
    maxW: { base: "85vw", sm: "70vw", md: "45vw" },
};

const wrapperSx = {
    display: "flex",
    flexDirection: "column",
    w: "unset",
};

const Copy: React.FC<CopyProps> = ({ text, ...props }) => {
    return (
        <Text
            textStyle="medium"
            dangerouslySetInnerHTML={text ? { __html: text } : undefined}
            {...textStyleMeta}
            {...props}
        />
    );
};

export const Article: React.FC<ArticleProps> = ({ heading, copy, children, ...props }) => {
    return (
        <Frame>
            <Box sx={wrapperSx} textColor={props.textColor} {...props}>
                {heading && (
                    <Heading
                        variant="subheading"
                        pb="2rem"
                        dangerouslySetInnerHTML={{ __html: heading }}
                        {...textStyleMeta}
                    />
                )}

                {Array.isArray(copy) &&
                    copy.map((item, index) => {
                        return <Copy text={item} key={index} />;
                    })}
                {!Array.isArray(copy) && <Copy text={copy} />}

                {children}
            </Box>
        </Frame>
    );
};
