import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { AppWidgetsProps, fetchWidgets } from "Root/utils/app-routes";
import { ErrorBoundary } from "Components/ErrorBoundary";

interface AppWidgetRootProps {
    widget: string;
    selectorId: string;
    component: React.FC<any>;
}

const AppRoutesRouteRoot = styled.div`
    width: 100%;
`;

const AppWidgetRoot: React.FC<AppWidgetRootProps> = ({ component: Component, selectorId, widget, ...props }) => {
    return (
        <AppRoutesRouteRoot id={selectorId}>
            <Component {...props} widget={widget} />
        </AppRoutesRouteRoot>
    );
};

export const UtilAppWidgets: React.FC = () => {
    const storeWidgets = useSelector((store) => store.app.widgets);
    const appReady = useSelector((store) => store.app.ready);
    const [appWidgets, setAppWidgets] = useState<AppWidgetsProps | null>(null);

    useEffect(() => {
        if (!storeWidgets) {
            return;
        }

        fetchWidgets(storeWidgets)
            .then((widgets) => {
                setAppWidgets(widgets);
            })
            .catch((error) => {
                console.log(`[WIDGETS] - failed to instantiate`);
            });
    }, [storeWidgets]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {appWidgets &&
                appReady &&
                appWidgets.map((appWidget) => {
                    return (
                        <ErrorBoundary key={appWidget.widgetId}>
                            <AppWidgetRoot
                                component={appWidget.component}
                                key={appWidget.widgetId}
                                widget={appWidget.widgetId}
                                selectorId={appWidget.selectorId}
                            />
                        </ErrorBoundary>
                    );
                })}
        </>
    );
};
