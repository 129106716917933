import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import { CodeExpired } from "../shared/master/CodeExpired";
import { LogoBar } from "../shared/LogoBar";

export const ShortcodeExpired: React.FC = ({ ...props }) => {
    let codeExpiredProps = useSelector((store) => store.template.data.document?.additionalTemplateData?.CodeExpired);

    if (!codeExpiredProps) {
        codeExpiredProps = {
            noCodeHeading: {
                content: "Configuration Code Expired",
            },
            noCodeInfo: {
                content:
                    "Your configuration short-code has expired. You can create a new code by entering the configurator",
            },
        };
    }

    return (
        <Box overflow="hidden" pos="relative" h="100vh" bgColor="blue.200">
            <LogoBar />
            <CodeExpired
                heading={codeExpiredProps?.noCodeHeading?.content}
                info={codeExpiredProps?.noCodeInfo?.content}
                cta={codeExpiredProps?.expiredCTA?.primary?.label?.content}
                to={codeExpiredProps?.expiredCTA?.primary?.to?.content}
                fontColor="page.0"
            />
        </Box>
    );
};

export default ShortcodeExpired;
