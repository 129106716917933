import { put } from "redux-saga/effects";
import { appSetDealership, appSetMap } from "Root/store/actions";
import { getDealershipDetails } from "./services";

export function* dealershipSaga(schema: any): any {
    const hasDealershipLookupService = schema.data.document.services.dealershipLookup;
    const hasMapAPIKey = schema.data.document.services.dealershipLookup.map?.apiKey;
    const hasDealershipStammNummer =
        schema.data.document.template?.Dealership?.stammNummer?.content ||
        schema.data.document.template?.Dealership?.stammNummer;

    if (hasMapAPIKey) {
        yield put(appSetMap({ apiKey: hasMapAPIKey, dealershipHasLocationCoords: false }));
    }

    if (hasDealershipLookupService && hasDealershipStammNummer) {
        const dealershipInformation = yield getDealershipDetails(hasDealershipLookupService.uri, {
            ...hasDealershipLookupService.params,
            stammNummer: hasDealershipStammNummer,
        });

        if (dealershipInformation) {
            const rawDealershipInfo = dealershipInformation.Standort[0]?.adressen[0];
            const dealershipDomainRaw = dealershipInformation.Standort[0]?.details.partnerDomainName;
            let dealershipDomain = dealershipDomainRaw;

            const dealershipDomainNeedsStripping =
                dealershipDomain && (dealershipDomain.includes("http://") || dealershipDomain.includes("https://"));

            if (dealershipDomain && dealershipDomainNeedsStripping) {
                dealershipDomain = dealershipDomain.replace(/^https?:\/\//, "");
            }

            const mappedDealershipInfo = {
                companyName: rawDealershipInfo.firmierung,
                addressType: rawDealershipInfo.adressart,
                city: rawDealershipInfo.ort,
                postcode: rawDealershipInfo.plz,
                email: rawDealershipInfo.email,
                telephone: rawDealershipInfo.telefon,
                street: rawDealershipInfo.strasse,
                street2: rawDealershipInfo.strasse2,
                lat: rawDealershipInfo.latitude,
                lon: rawDealershipInfo.longitude,
                domain: dealershipDomain,
                domainRaw: dealershipDomainRaw,
            };

            const hasCoords = mappedDealershipInfo.lat && mappedDealershipInfo.lon;

            if (hasMapAPIKey) {
                yield put(appSetMap({ apiKey: hasMapAPIKey, dealershipHasLocationCoords: hasCoords ? true : false }));
            }

            yield put(appSetDealership(mappedDealershipInfo));
        }
    }
}
