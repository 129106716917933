import { call, put, select } from "redux-saga/effects";
import { appSetDisclaimerFooter } from "Root/store/actions";
import { expandFooterDisclaimerDetails } from "./services";

const locale = (store: Store.Store) => store.app?.locale;

export function* disclaimerSaga(disclaimerKeysFromSchema: string[]): any {
    const localeFromStore = yield select(locale);
    const expandedDisclaimer = yield call(expandFooterDisclaimerDetails, localeFromStore, disclaimerKeysFromSchema);
    yield put(appSetDisclaimerFooter(expandedDisclaimer));
}
