import { createAction } from "typesafe-actions";
import { schemaTyped } from "~/store/sagas/side-effects/schema";
import type { expandShortcodeDetails } from "./sagas/side-effects/services";

export const STORE_CLEAR = "STORE_CLEAR";

export const APP_INIT = "APP_INIT";
export const APP_SET_PAGE_TITLE = "APP_SET_PAGE_TITLE";
export const APP_SET_READY = "APP_SET_READY";
export const APP_SET_PENDING = "APP_SET_PENDING";
export const APP_SET_LOCALE = "APP_SET_LOCALE";
export const APP_SET_VIEWPORT = "APP_SET_VIEWPORT";
export const APP_SET_ROUTES = "APP_SET_ROUTES";
export const APP_SET_WIDGETS = "APP_SET_WIDGETS";
export const APP_INIT_ERROR = "APP_INIT_ERROR";
export const APP_SET_VEHICLE = "APP_SET_VEHICLE";
export const APP_SET_VEHICLE_COLOR = "APP_SET_VEHICLE_COLOR";
export const APP_SET_DEALERSHIP = "APP_SET_DEALERSHIP";
export const APP_SET_MAP = "APP_SET_MAP";
export const APP_SET_PERSONALISED_PARAMS = "APP_SET_PERSONALISED_PARAMS";
export const APP_EXTERNAL_TRIGGER = "APP_EXTERNAL_TRIGGER";
export const APP_EXTERNAL_TRIGGER_FINISH = "APP_EXTERNAL_TRIGGER_FINISH";
export const APP_SET_DYNAMIC_WLTP_DATA = "APP_SET_DYNAMIC_WLTP_DATA";
export const APP_SET_DYNAMIC_SUMMARY_DATA = "APP_SET_DYNAMIC_SUMMARY_DATA";
export const APP_SET_PRICE = "APP_SET__PRICE";
export const APP_SET_TECHNICAL_SPECS = "APP_SET_TECHNICAL_SPECS";
export const APP_SET_MODEL_NAME = "APP_SET_MODEL_NAME";
export const APP_SET_EXPANDED_SHORTCODE = "APP_SET_EXPANDED_SHORTCODE";

export const APP_SET_DISCLAIMER_FOOTER = "APP_SET_DISCLAIMER_FOOTER";
export const APP_SET_VIDEO_PLAYPAUSE = "APP_SET_VIDEO_PLAYPAUSE";

export const TEMPLATE_SET = "TEMPLATE_SET";

export const MESSAGES_SET = "MESSAGES_SET";

export interface AppInitPayload {
    uuid?: string | null;
    locale?: string | null;
    forceDefault?: string | null;
}

/**
 * Clear:
 * resets the store to initial state
 */
export const storeClear = createAction(STORE_CLEAR)();

/**
 * ===
 * App actions
 * ===
 */

/**
 * Init:
 * Called after the app mounts
 */
export const appInit = createAction(APP_INIT)<AppInitPayload>();

/**
 * Set Ready:
 * Called after the schema properly resolves and all of the external side affects have finished resolving
 */
export const appSetReady = createAction(APP_SET_READY)();

/**
 * Set Pending:
 * Called after a single attempt to fetch the schema has failed
 * We use this to display a "Still processing" message.
 */
export const appSetPending = createAction(APP_SET_PENDING)();

/**
 * Trigger External:
 * This starts any external side effects
 */
export const appTriggerExternal = createAction(APP_EXTERNAL_TRIGGER)<schemaTyped>();

/**
 * Finish External:
 * This tells the app external services have finished resolving or otherwise timed out
 */
export const appExternalFinish = createAction(APP_EXTERNAL_TRIGGER_FINISH)();

/**
 * Dynamic WLTP data is present:
 * This information is gleamed from an external resource and may not always be valid/available
 */
export const appSetDynamicWLTPData = createAction(APP_SET_DYNAMIC_WLTP_DATA)<Store.DynamicWLTPParts>();

/**
 * Dynamic Summary data is present:
 * This information is gleamed from an external resource and may not always be valid/available
 */
export const appSetDynamicSummary = createAction(APP_SET_DYNAMIC_SUMMARY_DATA)<Store.DynamicSummaryItem>();

/**
 * Set isDesktop:
 */
export const appSetViewport = createAction(APP_SET_VIEWPORT)<string>();

/**
 * Set the locale of the messages being displayed:
 */
export const appSetLocale = createAction(APP_SET_LOCALE)<string>();

/**
 * Set the vehicle information
 */
export const appSetVehicle = createAction(APP_SET_VEHICLE)<Store.Vehicle>();

/**
 * Set the page title
 */
export const appSetTitle = createAction(APP_SET_PAGE_TITLE)<string>();

/**
 * Set the dealership information
 */
export const appSetDealership = createAction(APP_SET_DEALERSHIP)<Store.DealershipInfo>();

/**
 * Set the map information from the schema
 */
export const appSetMap = createAction(APP_SET_MAP)<Store.Map>();

/**
 * Set the shortcode from the schema
 */
export const appSetPersonalisedParams = createAction(APP_SET_PERSONALISED_PARAMS)<Store.PersonalisedParams>();

/**
 * Set the price and short code data
 */
export const appSetPrice = createAction(APP_SET_PRICE)<Store.PriceSummary>();

/**
 * Set Routes:
 * Gathers the routes from the template schema and stores them
 */
export const appSetRoutes = createAction(APP_SET_ROUTES)<Store.StoreRouteRoutes>();

/**
 * Set Widgets:
 * Gathers the widgets from the template schema and stores them
 */
export const appSetWidgets = createAction(APP_SET_WIDGETS)<Store.StoreWidgets>();

/**
 * Init Error:
 * Calls if the initial app setup errors out
 */
export const appInitError = createAction(APP_INIT_ERROR)<string>();

/**
 * Template Set:
 * Sets the resolved template into the store
 */
export const templateSet = createAction(TEMPLATE_SET)<any>();
type Awaited<T extends Promise<any>> = T extends Promise<infer R> ? R : never;

/**
 * Set the specification data:
 * This information is gleamed from an external resource and may not always be valid/available
 */
export const appSetTechnicalSpecs = createAction(APP_SET_TECHNICAL_SPECS)<Store.SpecSummary>();

export const appSetModelName = createAction(APP_SET_MODEL_NAME)<string>();
export const appSetExpandedShortcode =
    createAction(APP_SET_EXPANDED_SHORTCODE)<Awaited<ReturnType<typeof expandShortcodeDetails>>>();

/**
 * Set the disclaimer data:
 * From an external resource to set in the schema
 */
export const appSetDisclaimerFooter = createAction(APP_SET_DISCLAIMER_FOOTER)<Store.App>();

/**
 *  Set the video play/pause state:
 */
export const appSetVideoPlayPause = createAction(APP_SET_VIDEO_PLAYPAUSE)<boolean>();

/**
 * Set the messages to be used throughout the application.
 *
 * This allows us to access locale messages without needing to add something to the template.
 */
export const messagesSet = createAction(MESSAGES_SET)<Record<string, any>>();
