import { css } from "@emotion/react";

export const theme = {
    colors: {
        UICopyBase: "#3c3c3c",
        // Light grey
        UIBodyBase: "#eee",
        // Slightly darker grey
        UICalloutBorder: "#DFE4E8",
        // Darker grey
        UIBodyBaseAlt: "#d2d2d2",
        // Dark navy
        UICallout: "#00225A",
        // White
        UICalloutBase: "#FFF",
        // Light blue
        UICalloutLight: "#00B0F0",
        // Black
        Black: "#000000",
    },
    guttering: {
        landscape: "2rem",
        portrait: "1rem",
    },
    sizes: {
        whole: "1.4rem",
        half: "0.7rem",
        wholeHalf: "2.1rem",
        double: "2.8rem",
        desktopWhole: "7rem",
    },
    breakpoints: {
        mobileMediumMin: (...args: any) => css`
            @media (min-width: 450px) {
                ${css(...args)}
            }
        `,
        tabletMin: (...args: any) => css`
            @media (min-width: 768px) {
                ${css(...args)}
            }
        `,
        desktopMin: (...args: any) => css`
            @media (min-width: 1080px) {
                ${css(...args)}
            }
        `,
        desktopMediumMin: (...args: any) => css`
            @media (min-width: 1280px) {
                ${css(...args)}
            }
        `,
        desktopMediumPlusMin: (...args: any) => css`
            @media (min-width: 1440px) {
                ${css(...args)}
            }
        `,
        desktopLargeMin: (...args: any) => css`
            @media (min-width: 1920px) {
                ${css(...args)}
            }
        `,
        desktopLargePlusMin: (...args: any) => css`
            @media (min-width: 2260px) {
                ${css(...args)}
            }
        `,
        desktopLargestMin: (...args: any) => css`
            @media (min-width: 2560px) {
                ${css(...args)}
            }
        `,
    },
};
