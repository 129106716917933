import React from "react";
import styled from "@emotion/styled";

import { ReactComponent as LogoSVG } from "Assets/vw-logo.svg";
import { useSelector } from "react-redux";
import { Text } from "@chakra-ui/react";
import { Spinner } from "./master/Spinner";
import { OneHubTrackingEvents, useOHTracking } from "Root/utils/tracking";

interface LoadingWrapperProps {
    visible: boolean;
}

const Logo = styled(LogoSVG)`
    width: 6.25rem;
    height: 6.25rem;
`;

const LoadingWrapper = styled.div<LoadingWrapperProps>`
    position: absolute;
    top: 0;

    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${(props) => {
        return props.theme.old.colors.UICallout;
    }};

    @keyframes loadline {
        0% {
            left: -100%;
        }
        100% {
            left: 100%;
        }
    }

    z-index: 2;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;

    ${(props) =>
        props.visible &&
        `
        opacity: 1;
        pointer-events: all;
    `}
`;

const LoadingLine = styled.span`
    :before {
        position: absolute;
        width: 65%;
        height: 2px;
        content: "";
        background: rgba(51, 190, 255, 0.8);
        left: -100%;
        animation-name: loadline;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    display: inline-block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    top: 50%;
    margin-top: 7.25rem;
    overflow: hidden;
`;
let loadEventSent = false;
export const Loading: React.FC = () => {
    const appReady = useSelector((store) => store.app.ready);
    const isPending = useSelector((store) => store.app.pending);
    const marketURLParam = new URLSearchParams(window.location.search).get("market");
    const tracking = useOHTracking();
    if (appReady && !loadEventSent) {
        tracking(OneHubTrackingEvents.VWBasic_GenericFeatureApp_Load.toString(), {});
        loadEventSent = true;
    }

    return (
        <LoadingWrapper data-test-id="loadingOverlay" visible={!appReady}>
            <Logo />
            {marketURLParam !== "uk" && <LoadingLine> </LoadingLine>}
            {marketURLParam === "uk" && isPending && (
                <>
                    <Text textStyle="medium" my={{ base: "2" }} color="white">
                        Your video is still processing - please try again soon
                    </Text>
                    <Spinner />
                </>
            )}
        </LoadingWrapper>
    );
};
