export const createSrcSet = (src: string, extraSrcSet: string[]) => {
    let defaultSrc = src;

    if (!extraSrcSet || extraSrcSet.length < 1) {
        return src;
    }

    if (!src.endsWith("w")) {
        defaultSrc = src.concat(" 300w");
    }

    const newSrcList = [defaultSrc, ...extraSrcSet];

    return newSrcList.join(", ");
};

export const getTitle = () => document.title;
