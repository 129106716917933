import { call, put, select } from "redux-saga/effects";
import {
    appSetDynamicSummary,
    appSetDynamicWLTPData,
    appSetExpandedShortcode,
    appSetModelName,
    appSetPersonalisedParams,
    appSetPrice,
    appSetTechnicalSpecs,
    appSetVehicle,
} from "Root/store/actions";
import { expandShortcodeDetails } from "./services";

const locale = (store: Store.Store) => store.app.locale?.split("_")[0];
const storeVehicle = (store: Store.Store) => store.app.vehicle;
const storeTemplate = (store: Store.Store) => store.template;

const convertArrayToObject = (array: any[], key: string) =>
    array.reduce(
        (obj, item) => ({
            ...obj,
            [item[key]]: item,
        }),
        {}
    );

const decipherLocaleOfShortcodeResponse = (shortcodeResponse: any) => {
    try {
        const { language } = shortcodeResponse;

        return language;
    } catch {
        console.warn("[decipherLocaleOfShortcodeResponse] Could not decipher locale from shortcode response");

        return undefined;
    }
};
type Awaited<T extends Promise<any>> = T extends Promise<infer R> ? R : never;

export function* shortcodeWranglingSaga(schema: any, shortcode: string): any {
    const localeCode = yield select(locale);
    const vehicleFromStore = yield select(storeVehicle);
    const template = yield select(storeTemplate);
    /**
     * Here we handle passing in an override for testing
     * purposes.
     */
    const params = new URLSearchParams(window.location.search);
    const shortcodeOverride = params.get("shortcode");
    const runtimeShortcode = shortcodeOverride || shortcode;
    const expandedShortcode: Awaited<ReturnType<typeof expandShortcodeDetails>> = yield call(
        expandShortcodeDetails,
        runtimeShortcode,
        template
    );
    yield put(appSetExpandedShortcode(expandedShortcode));

    let personalisedParams: any = {
        customerName: schema.data.document?.customerName?.content || schema.data.document.customerName,
        shortCode: expandedShortcode ? runtimeShortcode : undefined,
    };

    if (expandedShortcode) {
        const shortcodeServiceResponseLocale = decipherLocaleOfShortcodeResponse(expandedShortcode);

        let trimLevelLabel: string | undefined;

        /**
         * Handles the retrieval of the Trim name (Like ID.3 Pro S)
         * This is functionally seperate from the WLTP dynamic data retrieval
         * but is a dependency for it so it is done seperately first.
         */
        const consolidatedData = expandedShortcode.consolidatedData!;
        let carlineName =
            consolidatedData.model_description ||
            expandedShortcode.vgso?.consolidatedData?.carlineName?.replace("Der neue ", "");
        carlineName = carlineName?.replace(/New /g, "") || "";

        try {
            trimLevelLabel = consolidatedData?.meta?.modelFilter?.find((modelFilter: any) => {
                return modelFilter.type === "EquipmentLine";
            })?.text;

            const ukTrimLevelLabel = consolidatedData?.meta?.modelFilter?.find((modelFilter: any) => {
                return modelFilter.localizedName === "UK_trim";
            });
            const usTrimLevelLabel = consolidatedData?.meta?.modelFilter?.find((modelFilter: any) => {
                return modelFilter.localizedName === "US_Version";
            })?.text;
            const auTrimLevelLabel = consolidatedData?.meta?.modelFilter?.find((modelFilter: any) => {
                return modelFilter.localizedName === "VERSION";
            });
            const dkTrimLevelLabel = consolidatedData?.meta?.modelFilter?.find(
                (f: any) => f.localizedName === "DK_VERSION"
            )?.text;

            if (auTrimLevelLabel) {
                const auModel = carlineName;
                trimLevelLabel = auTrimLevelLabel ? `${auModel} ${auTrimLevelLabel.text}` : auModel;
            } else if (dkTrimLevelLabel) {
                trimLevelLabel = dkTrimLevelLabel;
            } else if (usTrimLevelLabel) {
                trimLevelLabel = usTrimLevelLabel;
            } else if (ukTrimLevelLabel) {
                console.info("[shortcode]: UK market identified");
                const ukModel = carlineName;
                trimLevelLabel = ukTrimLevelLabel ? `${ukModel} ${ukTrimLevelLabel.text}` : ukModel;
            } else if (shortcodeServiceResponseLocale === "fr") {
                trimLevelLabel = consolidatedData.options?.find(
                    (options) => options.category === "SALESGROUP"
                )?.description;
            } else {
                trimLevelLabel = carlineName + " " + trimLevelLabel;
            }

            if (trimLevelLabel) {
                personalisedParams.carMarketingName = trimLevelLabel;

                yield put(
                    appSetVehicle({
                        ...vehicleFromStore,
                        expandedPrCode: {
                            ...vehicleFromStore.expandedPrCode,
                            trim: {
                                ...vehicleFromStore.expandedPrCode?.trim,
                                label: trimLevelLabel,
                            },
                        },
                    })
                );
            }
        } catch (e) {
            console.warn(`[External - ShortcodeExpansion - Trim Label] - Error retrieving trim label - ${e}`);
        }

        /**
         * Handles the very loose mapping of information from the VICI shortcode service
         * into usable information. If any of it fails/is undefined then it will not be set
         */
        let longName = expandedShortcode?.consolidatedData?.options?.find((option) => option.category === "TYPE")
            ?.description!;

        let modelName =
            carlineName === "Atlas"
                ? "Atlas"
                : (carlineName !== "ID.3" &&
                      longName?.split("  ")?.length > 1 &&
                      longName?.split("  ")?.[0].replace("4MOTION", "")) ||
                  undefined;
        if (shortcodeServiceResponseLocale === "da" && trimLevelLabel) yield put(appSetModelName(trimLevelLabel!));
        else yield put(appSetModelName(modelName!));
        try {
            const wltpData = consolidatedData.wltp_data;
            const wltpCategories = wltpData?.interpolations;
            const CO2 = wltpCategories?.find(
                (category) =>
                    (category.energy_management_type === "PURE" || category.energy_management_type === "DEPLETING") &&
                    category.value_type === "CO2"
            )?.value!;
            const effeciencyClass = "A+++"; //WLTP Data from VGF doesn't contains efficiency class data anymore
            const consumption = wltpCategories?.find(
                (category) => category.value_type === "CONSUMPTION" && category.phase === "COMBINED"
            );
            const consumptionAmount = consumption?.value?.toFixed(1)!;
            const consumptionUnit = consumption?.unit!;

            const hasAllLegalParts =
                typeof consumptionUnit !== undefined &&
                typeof consumptionAmount !== undefined &&
                typeof CO2 !== undefined; // &&
            //typeof effeciencyClass !== undefined;

            if (hasAllLegalParts) {
                yield put(
                    appSetDynamicWLTPData({
                        CO2,
                        consumptionAmount: (+consumptionAmount / 10).toLocaleString(localeCode),
                        effeciencyClass,
                        consumptionUnit: consumptionUnit === "Wh/km" ? "kWh/100 km" : consumptionUnit,
                    })
                );
            }
        } catch (e) {
            console.warn(`[External - ShortcodeExpansion - WLTP Data] - Error retrieving deep wltp information - ${e}`);
        }

        /**
         * Handles an attempt at building a dynamic summary screen
         */
        try {
            const configData = expandedShortcode.vgso;
            const features = [
                ...(configData?.expandedConfiguration?.features ?? []),
                configData?.vehicleConfiguration?.exterior,
                configData?.vehicleConfiguration?.interior,
            ]?.filter((f) => f);

            const mediaAssets = expandedShortcode.consolidatedData?.meta?.mediaAssets;
            const interior = features.find((f) => f?.isInterior);
            const exterior = features.find((f) => f?.isExterior);
            const { options } = expandedShortcode.expandedConfiguration!;

            const tiles = mediaAssets?.filter((asset: any) => {
                return asset.mpsId.includes("Tile") && asset.type !== "ERROR";
            })!;

            const enrichedTiles = tiles.map((tile: any) => {
                tile = JSON.parse(JSON.stringify(tile));
                if (tile.mpsId.includes("ColorTile")) {
                    tile.key = "exterior";
                    tile.name = exterior?.name;
                }

                if (tile.mpsId.includes("InteriorTile")) {
                    tile.key = "interior";
                    tile.name = interior?.name;
                    //tile.additionalInfo = interior.designElements;
                }

                if (tile.mpsId.includes("RimTileMaxFit")) {
                    tile.key = "wheel";

                    const rimFeatureFromExpandedConfiguration = options!.find((feature: any) => {
                        return feature.category === "RAD";
                    });

                    const rimFeatureName = rimFeatureFromExpandedConfiguration?.description;

                    tile.name = rimFeatureName;
                }

                return tile;
            });
            console.log(enrichedTiles);
            yield put(appSetDynamicSummary(convertArrayToObject(enrichedTiles, "key")));
        } catch (e) {
            console.warn(`[External - ShortcodeExpansion - Summary Build] - Error retrieving tile information - ${e}`);
        }

        /**
         * Handles the price retrieval
         *
         */
        try {
            const priceDataModel = consolidatedData.meta?.configurationPrice;
            /* Total price */
            const totalPrice =
                expandedShortcode.priceOverride ??
                expandedShortcode.pricingData?.body?.amount ??
                priceDataModel?.total?.[0].price;
            yield put(appSetPrice({ totalPrice }));
        } catch (e) {
            console.warn(
                `[External - ShortcodeExpansion - Price Data] - Error retrieving deep pricing information - ${e}`
            );
        }

        /**
         * Handles collecting technical specifications from the shortcode service
         *
         */
        try {
            const specDataModel = consolidatedData.meta?.modelFilter;
            const specDataTech = (consolidatedData as any)["technicalSpecifications"];

            const gears = specDataModel?.find((item) => item.type === "Gear")!;
            const maxPower = specDataModel?.find((item) => item.type === "EnginePower");
            const netBattery = specDataModel?.find((item) => item.type === "Netto-Batterieenergieinhalt");

            const ACBattHead = specDataTech.find((item: any) => {
                if (!item.family) return null;
                return item.family.value === "BATTERIE / LADESYSTEM";
            });

            const EmisTorqHead = specDataTech.find((item: any) => {
                if (!item.family) return null;
                return item.family.value === "MOTOR, GETRIEBE, ELEKTRIK";
            });

            const AcCharging = ACBattHead.specifications.find(
                (item: any) => item.code.value === "Ladedauer AC 11 KW 0% - 100% SOC"
            );

            const battery = ACBattHead.specifications.find((item: any) => item.code.value === "Batterietyp");

            const emissionNorm = EmisTorqHead.specifications.find((item: any) => item.code.value === "Emissionsnorm");

            const maxTorque = EmisTorqHead.specifications.find((item: any) => item.code.value === "Max. Drehmoment");

            yield put(
                appSetTechnicalSpecs({
                    gears,
                    maxPower,
                    netBattery,
                    AcCharging,
                    emissionNorm,
                    maxTorque,
                    battery,
                })
            );
        } catch (e) {
            console.warn(
                `[External - ShortcodeExpansion - Price Data] - Error retrieving technical specifications information - ${e}`
            );
        }
    }

    /**
     * Personalised Params direct from the schema
     */
    yield put(appSetPersonalisedParams(personalisedParams));
}
