import { ComponentStyleConfig } from "@chakra-ui/react";

export const themeDef: ComponentStyleConfig = {
    baseStyle: {
        fontWeight: "normal",
        minHeight: "3rem",
        borderRadius: "3rem",
        textDecoration: "none",
        width: "14rem",
        fontSize: { base: "1rem", xs: "1.1rem", sm: "1.3rem" },
        padding: { base: "0.6rem", xs: "1.1rem", sm: "1.6rem" },
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    sizes: {
        small: {
            h: "2rem",
            w: "13rem",
        },
        medium: {
            h: "2rem",
            w: { base: "13rem", sm: "14rem" },
        },
        large: {
            h: "2rem",
            w: { base: "14rem", sm: "18rem" },
        },
    },
    variants: {
        primary: {
            bg: "page.0",
            border: "2px",
            borderColor: "blue.200",
            color: "blue.200",
            _hover: {
                bg: "blue.200",
                border: "2px",
                borderColor: "page.0",
                color: "page.0",
            },
        },
        primaryNoBorder: {
            bg: "page.0",
            color: "blue.200",
            _hover: {
                bg: "blue.200",
                color: "page.0",
            },
        },
        secondary: {
            bg: "blue.100",
            border: "2px",
            borderColor: "blue.100",
            color: "blue.200",
            _hover: {
                bg: "none",
                border: "2px",
                borderColor: "blue.100",
                color: "blue.100",
            },
        },
        tertiary: {
            bg: "none",
            border: "none",
            color: "page.0",
            textDecoration: "underline",
        },
        clear: {
            bg: "none",
            border: "none",
            color: "page.0",
            textAlign: "left",
            textDecoration: "none",
            fontWeight: "normal",
            width: "30rem",
        },
    },
    defaultProps: {
        variant: "primary",
        size: "medium",
    },
};

export type ThemeDef = Omit<ComponentStyleConfig, "variants"> & typeof themeDef;
