import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    appInit,
    AppInitPayload,
    appSetViewport,
    appSetReady,
    storeClear,
    appSetTitle,
    appSetDynamicWLTPData,
    appSetVideoPlayPause,
} from "Store/actions";
import { trackEvent } from "Utils/events-tracking";

/**
 * Store specific Hooks
 */
export const useStoreClear = () => {
    const dispatch = useDispatch();

    return () => {
        dispatch(storeClear());
    };
};

/**
 * App specific Hooks
 */
export const useStoreAppInit = () => {
    const dispatch = useDispatch();

    return (props: AppInitPayload) => {
        dispatch(appInit(props));
    };
};

export const useStoreAppReady = () => {
    const dispatch = useDispatch();

    return () => {
        dispatch(appSetReady());
    };
};

export const useStoreAppLocale = () => {
    return useSelector((store) => store.app.locale);
};

export const useStandardLocaleFormat = () => {
    const locale = useSelector((store) => store.app.locale);
    const regex = /[a-zA-Z]+_[a-zA-Z]+/i;

    if (locale) {
        if (regex.test(locale)) {
            const localeParts = locale.split("_");

            return `${localeParts[0]}-${localeParts[1].toUpperCase()}`;
        }
    }

    return undefined;
};

export const useStoreAppVehicle = () => {
    return useSelector((store) => store.app.vehicle);
};

export const useStoreAppVehicleColor = () => {
    return useSelector((store) => store.app.vehicle.expandedPrCode.exterior);
};

export const useStoreAppViewport = () => {
    return useSelector((store) => store.app.viewport);
};

export const useStoreAppIsDesktop = () => {
    return useSelector((store) => store.app.viewport.includes("desktop"));
};

export const useStoreAppIsTablet = () => {
    return useSelector((store) => store.app.viewport === "tablet");
};

export const useStoreAppIsTabletOrLarger = () => {
    return useSelector((store) => store.app.viewport === "tablet" || store.app.viewport.includes("desktop"));
};

export const useStoreSetAppViewport = () => {
    const dispatch = useDispatch();

    return (props: string) => {
        dispatch(appSetViewport(props));
    };
};

export const useStoreAppTitle = () => {
    return useSelector((store) => store.app.title);
};

export const useStoreSetAppTitle = () => {
    const dispatch = useDispatch();

    return (props: string) => {
        dispatch(appSetTitle(props));
    };
};

export const useStoreAppDealership = () => {
    return useSelector((store) => store.app.dealership);
};

export const useStoreTemplateID = () => {
    return useSelector((store) => store.template.data.document.type);
};

export const useStoreSetDynamicWLTPData = () => {
    const dispatch = useDispatch();

    return (props: Store.DynamicWLTPParts) => {
        dispatch(appSetDynamicWLTPData(props));
    };
};

export const useStoreAppPrice = () => {
    return useSelector((store) => store.app.price);
};

export const useStoreShortCode = () => {
    return useSelector((store) => store.app.personalisedParams?.shortCode);
};

export const useStoreAppSetVideoPlaying = () => {
    const dispatch = useDispatch();

    return (props: boolean) => {
        dispatch(appSetVideoPlayPause(props));
    };
};

export const useIsUKMasterSchema = () => {
    return (
        useSelector((store) => store.template.data.document.type) === "master" &&
        new URLSearchParams(window.location.search).get("market") === "uk"
    );
};

/**
 * Custom Hooks
 */
export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const useTracking = (componentId: string) => {
    useEffect(() => {
        trackEvent(`template page ${componentId} loaded`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export let SearchCodes: Record<string, string> = {
    "ID.3": "BQID",

    "ID.4": "BQIE",

    "ID.5": "BQIF",
    Arteon: "BQHG",
    "Arteon Shooting Brake": "BQFF",
    default: "",
};

export const useDynamicContent = (input: string, fallbacks?: { [key: string]: string }) => {
    const customerName = useSelector((store) => store.app.personalisedParams?.customerName);
    const carMarketingName = useSelector((store) => store.app.personalisedParams?.carMarketingName);
    const shortCode = useSelector((store) => store.app.personalisedParams?.shortCode);
    const searchCode = SearchCodes[carMarketingName?.split(" ")[0] as any as keyof typeof SearchCodes];

    if (!input) {
        return input;
    }

    let output = input;

    /** Initial Pass to remove placeholders */
    if (customerName) {
        output = output.replaceAll(`\${_customerName_}`, customerName);
    }

    if (carMarketingName) {
        output = output.replaceAll(`\${_carMarketingName_}`, carMarketingName);
    }

    if (shortCode) {
        output = output.replaceAll(`\${_shortCode_}`, shortCode);
    }

    if (searchCode) {
        output = output.replaceAll(`\${_searchCode_}`, searchCode);
    }

    // eslint-disable-next-line no-template-curly-in-string
    if (output.includes("${_customerName_}") && output.includes("${_carMarketingName_}")) {
        if (fallbacks && fallbacks.noNameNoCar) {
            return fallbacks.noNameNoCar;
        }

        return "no-name-no-car";
    }

    // eslint-disable-next-line no-template-curly-in-string
    if (output.includes("${_carMarketingName_}")) {
        if (fallbacks && fallbacks.noCar) {
            return fallbacks.noCar.replaceAll(`\${_customerName_}`, customerName!);
        }

        return "no-car";
    }

    // eslint-disable-next-line no-template-curly-in-string
    if (output.includes("${_customerName_}")) {
        if (fallbacks && fallbacks.noName) {
            return fallbacks.noName.replaceAll(`\${_carMarketingName_}`, carMarketingName!);
        }

        return "no-name";
    }

    // eslint-disable-next-line no-template-curly-in-string
    if (output.includes("${_shortCode_}")) {
        if (fallbacks && fallbacks.noShortCode) {
            return fallbacks.noShortCode.replaceAll(`\${_shortCode_}`, shortCode!);
        }

        return "no-shortCode";
    }

    return output;
};

export const useMessages = (messages: string | string[]) => {
    const messagesFromStore = useSelector((store) => store.messages);
    if (!messagesFromStore) return {};
    if (!Array.isArray(messages)) {
        if (typeof messagesFromStore[messages] === "undefined") {
            // console.warn(`[Messages]: Failed to return message for id: ${messages}`);
        }

        return messagesFromStore[messages];
    }

    const messageEmptyDict: any = {};
    const messagesDict = messages.reduce((messagesDictPrev, messageId) => {
        messagesDictPrev[messageId] = messagesFromStore[messageId];

        return messagesDictPrev;
    }, messageEmptyDict);

    return messagesDict;
};
