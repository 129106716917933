export const IS_DESKTOP_MEDIUM_MATCH_MEDIA_QUERY = "(min-width: 1280px)";
export const IS_DESKTOP_MATCH_MEDIA_QUERY = "(min-width: 1080px)";
export const IS_TABLET_MATCH_MEDIA_QUERY = "(min-width: 768px)";

export const getViewport = (): string => {
    if (window.matchMedia(IS_DESKTOP_MEDIUM_MATCH_MEDIA_QUERY).matches) {
        return "desktopMedium";
    }

    if (window.matchMedia(IS_DESKTOP_MATCH_MEDIA_QUERY).matches) {
        return "desktop";
    }

    if (window.matchMedia(IS_TABLET_MATCH_MEDIA_QUERY).matches) {
        return "tablet";
    }

    return "mobile";
};
