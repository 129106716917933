import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as LogoSVG } from "Assets/vw-logo.svg";

interface LogoPaddingProps {
    left?: boolean;
    right?: boolean;
}

const LogoWrapper = styled.div`
    z-index: 1;
    transform: translate(0, 6px);
`;

const LogoPadding = styled.div<LogoPaddingProps>`
    height: 48px;
    top: 0;
    border-bottom: 2px solid white;
    position: absolute;

    ${(props) =>
        props.left &&
        `
		left: 0;
        width: calc(66.33vw - 9px);
	`}

    ${(props) =>
        props.right &&
        `
		right: 0;
        left: calc(66.33vw - 9px + 48px + 24px);
	`}
`;
const LogoHousing = styled.div`
    position: absolute;
    width: 100%;
    text-align: center;
    top: 24px;
    left: 0;
    font-weight: bold;
    color: white;
`;

const Logo = styled(LogoSVG)`
    position: absolute;
    height: 48px;
    width: 48px;
    left: calc(66.33vw + 3px);
`;

export const LogoBar: React.FC = (...props) => {
    return (
        <LogoWrapper>
            <>
                <LogoPadding left />
                <LogoPadding right />
            </>
            <LogoHousing>
                <Logo />
            </LogoHousing>
        </LogoWrapper>
    );
};
