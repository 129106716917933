import { ChakraProvider } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { UtilAppOrientation } from "Components/utils/UtilAppOrientation";
import { UtilAppRoutes } from "Components/utils/UtilAppRoutes";
import { UtilAppTitleSetter } from "Components/utils/UtilAppTitleSetter";
import { UtilAppWidgets } from "Components/utils/UtilAppWidgets";
import { UtilBootstrapApp } from "Components/utils/UtilBootstrapApp";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ProviderStore } from "~/components/utils/Providers";
import { theme } from "./styles/theme";

/**
 * Turns URL path into router basename by removing everything after the last slash
 */
const getBasename = (path: string): string => {
    return path.substr(0, path.lastIndexOf("/"));
};

const NoThemeCriticalCSS = styled.div``;

export const App: React.FC = () => {
    return (
        <NoThemeCriticalCSS data-testid="app-wrapper">
            <ChakraProvider theme={theme}>
                <ProviderStore>
                    {/* Routable components */}
                    <Router basename={getBasename(window.location.pathname)}>
                        {/* Util apps outside of the routing */}
                        <UtilBootstrapApp />
                        <UtilAppOrientation />
                        <UtilAppTitleSetter />

                        {/* App Widgets mounted */}
                        <UtilAppWidgets />

                        {/* App routes mounted */}
                        <UtilAppRoutes />
                    </Router>
                </ProviderStore>
            </ChakraProvider>
        </NoThemeCriticalCSS>
    );
};
