import { ComponentStyleConfig } from "@chakra-ui/react";

export const themeDef: ComponentStyleConfig = {
    baseStyle: {
        padding: { base: "1.5rem", md: "4rem", lg: "6rem" },
        width: "100%",
    },
    variants: {},
};

export type ThemeDef = Omit<ComponentStyleConfig, "variants"> & typeof themeDef;
