import { MESSAGES_SET } from "Store/actions";

const runtimeDefaultState = {};

export const MessagesReducer = (state = runtimeDefaultState, action: any) => {
    switch (action.type) {
        case MESSAGES_SET:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
