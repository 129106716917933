import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "Root/App";
import "Root/critical.css";
import { isDebugRuntime } from "./utils/debug";

Sentry.configureScope(function (scope) {
    scope.setLevel(Sentry.Severity.Warning);
});

Sentry.init({
    dsn: "https://52a5e7d337b148e7840ab6865965c1c0@sentry-dev.zlthunder.net/11",
    integrations: [
        new CaptureConsole({
            levels: ["warn", "error"],
        }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    beforeSend(event) {
        /**
         * Stops errors from debug/dev from being logged
         */
        if (isDebugRuntime) {
            return null;
        }

        return event;
    },
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
