import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "typesafe-actions";
import { appExternalFinish, APP_EXTERNAL_TRIGGER } from "~/store/actions";
import { schemaTyped } from "~/store/sagas/side-effects/schema";
import { dealershipSaga } from "./side-effects/dealership";
import { shortcodeWranglingSaga } from "./side-effects/shortcode";
import { disclaimerSaga } from "./side-effects/disclaimer";

function* externalEffects(action: PayloadAction<string, schemaTyped>): any {
    const schema = action.payload;
    const disclaimerNames = schema.data.document.template.Footer?.disclaimerKeys?.content;
    const schemaHasShortcode = schema.data.service.stream.config.setupShortcode?.code || schema.data.document.shortcode;
    const schemaIsMaster = schema.data.document.type === "master";
    const templateHasFooter = schemaIsMaster ? schema.data.document.template?.Footer : undefined;
    const marketURLParam = new URLSearchParams(window.location.search).get("market");

    /** Check for services outlined in the schema */
    if (schema.data.document.services) {
        /**
         * Prefetch the dealership details
         */
        if (schema.data.document.services.dealershipLookup) {
            yield call(dealershipSaga, schema);
        }
    }

    if (schemaHasShortcode) {
        yield call(shortcodeWranglingSaga, schema, schemaHasShortcode);
    }

    if (schemaIsMaster && templateHasFooter && marketURLParam !== "uk") {
        yield call(disclaimerSaga, disclaimerNames);
    }

    yield put(appExternalFinish());
}

// Watchers
export function* externalSaga(): SagaIterator {
    yield takeLatest(APP_EXTERNAL_TRIGGER, externalEffects);
}
