import React from "react";
import styled from "@emotion/styled";
import { Box as ChakraBoxbase, BoxProps, useStyleConfig } from "@chakra-ui/react";
import { ThemeDef } from "./theme-def";

interface FrameProps extends BoxProps {}

const Export = styled(ChakraBoxbase)<ThemeDef>``;

export const Frame: React.FC<FrameProps> = (props) => {
    /**
     * Pulls the `Frame` custom component styling from the theme
     */
    const styles = useStyleConfig("Frame");

    return <Export __css={styles} {...props} />;
};
