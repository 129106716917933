export interface GetTemplateRoutesArg {
    [key: string]: Pick<TemplateSchema.Page, "config">;
}

export interface GetTemplateWidgetsArg {
    [key: string]: Pick<TemplateSchema.Widget, "config">;
}

/**
 * Get dictionary of routes from template
 */
export const getTemplateRoutes = (pages: GetTemplateRoutesArg): Store.StoreRouteRoutes =>
    Object.fromEntries(
        Object.entries(pages)
            // Don't include disabled pages
            .filter(([, page]) => !page?.config?.disabled)
            // Return required props
            .map(([key, page]) => [
                page.config.path,
                {
                    ...page.config,
                    selectorId: page.config.path,
                    pageId: key,
                },
            ])
    );

/**
 * Get dictionary of routes from template
 */
export const getTemplateWidgets = (widgets: GetTemplateWidgetsArg): Store.StoreWidgets =>
    Object.fromEntries(
        Object.entries(widgets)
            // Don't include disabled pages
            .filter(([, widget]) => !widget?.config?.disabled)
            // Return required props
            .map(([key, widget]) => [
                key,
                {
                    ...widget.config,
                    selectorId: widget.config.id,
                    widgetId: key,
                },
            ])
    );
