import React, { useEffect } from "react";
import { useQuery, useStoreAppInit } from "Root/hooks";

export const UtilBootstrapApp: React.FC = () => {
    const appInit = useStoreAppInit();
    const query = useQuery();

    useEffect(() => {
        appInit({
            uuid: query.get("u"),
            locale: query.get("locale"),
            forceDefault: query.get("default"),
        });
    }, [appInit, query]);

    return null;
};
