import React, { useEffect } from "react";
import { useStoreAppTitle } from "Root/hooks";

export const UtilAppTitleSetter: React.FC = () => {
    const title = useStoreAppTitle();

    useEffect(() => {
        document.title = title;
    }, [title]);

    return null;
};
