import { FeatureAppDefinition } from "@feature-hub/core";
import { ReactFeatureApp } from "@feature-hub/react";
import React from "react";
import { setBaseUrl } from "./baseURL";
import { FeatureServicesContext } from "./context/isFeatureAppContext";
import { TrackingManagerV1 as TrackingService } from "@volkswagen-onehub/tracking-service";
import { TrackingServiceConfigV1 } from "@volkswagen-onehub/tracking-service-config";
import { ApiKeyProviderV1 } from "@volkswagen-onehub/api-key-provider";
import { ServiceConfigProviderV1 } from "@volkswagen-onehub/service-config-provider";

declare var __webpack_public_path__: string;

export type FeatureAppDependencies = {
    "layer-manager": any;
    tracking: TrackingService;
    "tracking-service-config": TrackingServiceConfigV1;
    "service-config-provider": ServiceConfigProviderV1;
    "api-key-provider": ApiKeyProviderV1;
};

const FeatureApp: FeatureAppDefinition<ReactFeatureApp, FeatureAppDependencies, {}> = {
    dependencies: {
        externals: { react: "^16.13.1" },
        featureServices: {
            "layer-manager": "2.7.1",
            tracking: "1.0.0",
            "tracking-service-config": "1.0.0",
            "service-config-provider": "1.0.0",
            "api-key-provider": "^1.0.0",
        },
    },
    optionalDependencies: {
        featureServices: {},
    },
    ownFeatureServiceDefinitions: [],

    create: (e) => {
        return {
            render: () => {
                //e.baseUrl = "https://zldt033.zerolight.io:8443/proxy"
                console.log(e.baseUrl);
                setBaseUrl(e.baseUrl as string);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                __webpack_public_path__ = (e.baseUrl as string) + "/";
                return <AppLoader services={e.featureServices} />;
            },
        };
    },
};

export let environmentTenant = undefined;

function AppLoader(props: { services: FeatureAppDependencies }) {
    let [App, setApp] = React.useState<any>();
    environmentTenant =
        props.services["service-config-provider"].configs["oneapi-victor"]!["tenantPrivate"] ||
        props.services["service-config-provider"].configs["oneapi-victor"]!["tenantComercial"];
    React.useMemo(() => import("./featureappEntry").then((r) => setApp(r.default)), []);
    return (App && <FeatureServicesContext.Provider value={props}>{App}</FeatureServicesContext.Provider>) || false;
}

export default FeatureApp;
