import { ChakraTheme, extendTheme } from "@chakra-ui/react";
import { themeDef as Button } from "Components/shared/master/Button/theme-def";
import { themeDef as Frame } from "Components/shared/master/Frame/theme-def";
import { themeDef as Heading } from "Components/shared/master/Heading/theme-def";
import { themeDef as Text } from "Components/shared/master/Text/theme-def";
import { theme as oldTheme } from "Styles/old-theme";

export type Theme = typeof extendThemeFlat & ChakraTheme;

const extendThemeFlat = {
    components: {
        Button,
        Frame,
        Heading,
        Text,
    },
    colors: {
        page: {
            0: "#FFF",
            100: "#eee",
            200: "#DFE4E8",
            300: "#d2d2d2",
            400: "#3c3c3c",
            500: "#a3a",
        },
        blue: {
            100: "#00B0F0",
            150: "rgba(20, 50, 103, 1)",
            200: "#00225A",
            250: "rgba(0, 34, 90, 1)",
        },
        black: {
            0: "#000000",
        },
        foreground: "black",
    },
    fonts: {
        body: "bodyBase",
    },
    breakpoints: {
        base: "0px",
        xs: "450px",
        sm: "768px",
        "sm-md": "1024px",
        md: "1280px",
        lg: "1440px",
        xl: "1920px",
        "2xl": "2260px",
        "3xl": "2560px",
    },
    textStyles: {
        "card-reveal": {
            fontSize: ".75rem",
            textAlign: "center",
        },
        small: {
            fontSize: { base: "0.7rem", sm: "1rem", md: "1.2rem" },
        },
        medium: {
            fontSize: { base: "1rem", sm: "1.2rem", md: "1.5rem" },
            lineHeight: { base: 1, md: 1.75 },
        },
        subheading: {
            fontSize: { base: "1.6rem", sm: "2.1rem", md: "2.7rem" },
        },
        heading: {
            fontSize: { base: "1.5rem", xs: "2.0rem", sm: "2.8rem", md: "3.5rem" },
            textAlign: { xs: "unset", md: "center" },
            letterSpacing: { base: 1, md: 2 },
            lineHeight: { base: 1, md: 1.2 },
        },
    },
    old: { ...oldTheme },
};

const extendThemeFlatDark = {
    colors: {
        foreground: "white",
    },
    old: { ...extendThemeFlat },
};

export const theme = extendTheme(extendThemeFlat);
export const themeDark = extendTheme(extendThemeFlatDark);
