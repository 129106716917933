import { TEMPLATE_SET } from "Store/actions";
import masterTemplate from "~/../public/schema/template-schema-master.json";
import enGBLocale from "~/../public/locales/master/common_en_gb.json";
import { parseTemplateStrings } from "../sagas/side-effects/locales";

const messageIds = Object.keys(enGBLocale);
const translateMasterSchema = parseTemplateStrings(messageIds, enGBLocale, masterTemplate);
const runtimeDefaultState: Store.Template = process.env.STORYBOOK ? translateMasterSchema : {};

export const TemplateReducer = (state = runtimeDefaultState, action: any) => {
    switch (action.type) {
        case TEMPLATE_SET:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
